import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CloudinaryContext } from "cloudinary-react";
// import { Image } from "cloudinary-react";
import "./App.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Container,
  Card,
  CardImg,
  CardBody,
} from "reactstrap";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLocationArrow
} from "@fortawesome/free-solid-svg-icons"

import locationSv1 from "./assets/images/locations/sv1.png";
import locationGt1 from "./assets/images/locations/gt1.png";
import locationMx1 from "./assets/images/locations/mx1.png";
import { ReactComponent as TiktokIcon } from "./assets/images/tiktok.svg";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [svModal, setSvModal] = useState(false);
  const toggleSv = () => setSvModal(!svModal);
  const [guatModal, setGuatModal] = useState(false);
  const toggleGuat = () => setGuatModal(!guatModal);
  const [mexModal, setMexModal] = useState(false);
  const toggleMex = () => setMexModal(!mexModal);
  const showInMapClicked = (location) => {
    if (location === "sv1"){
      window.open("https://goo.gl/maps/DVpb54YXAQmQv6WR6");
    } else if (location === "sv2"){
      window.open("https://goo.gl/maps/z6QhEutpzriDAgCv7");
    } else if (location === "gt1"){
      window.open("https://goo.gl/maps/pVUE65XoGJmLFxz87");
    } else if (location === "mx1"){
      window.open("https://goo.gl/maps/vHTCxavT9oUfzm2T9");
    }
  };
  const toggleMenus = () => setMenusModal(!menusModal);
  const [menusModal, setMenusModal] = useState(false);

  return (
    <CloudinaryContext cloudName="da3qtg7t5">
    <div className="App" id="landing-page">
      <section id="hero-section">
        <Navbar light expand="md">
          <NavbarBrand href="/">
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto mr-auto" navbar>
              <NavItem>
                <Link
                  to="our-story-section"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  <NavLink href="/">nuestra historia</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="products-section"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  <NavLink href="/">productos</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="catering-section"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  <NavLink href="/">catering</NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="locations-section"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  <NavLink href="/">ubicaciones</NavLink>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <Container id="hero-container">
          <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Flogos%2FRH%20LOGO%20BLANCO.png?alt=media&token=a76df2d4-144b-489f-aa85-1f3d63e815a8'} alt="recien horneado bakery"/>
          {/* <Image
            publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669064703/Recien%20Horneado%20Bakery/rh_logo_pp3npx.png"
					  // width="100%"
            // className="hide-on-mobile"
            // onClick={!isGuest ? this.toggle : this.alertForGuest}
          />  */}
          <Row>
            <Col>
              <Button onClick={toggleMenus}>MENÚS</Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="our-story-section">
        <Container>
          <Row>
            <Col>
              <img className="hide-on-mobile has-shadow" src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fimages%2FIMG_0407%201.png?alt=media&token=90ca82ff-128a-4f76-b311-f3e1302cbd45'} alt="Our story"/>
              {/* <Image
                className="hide-on-mobile has-shadow"
                publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669914244/Recien%20Horneado%20Bakery/our_story_t781ow.jpg"
              />  */}
            </Col>
            <Col className="full-width-col-on-mobile">
              
              <div id="our-story-text">
                <h3 className="mb-3">nuestra historia</h3>
                <p>
                  Inspirados en la calidad y exquisito sabor de la panadería europea, en el alto estándar de nuestros países en la calidad de su cafe, y la falta de espacios únicos para combinarlos, nace el sueño fundar Recién Horneado Bakery, una cadena de cafeterías y panaderías que ofrece una variedad única en pan, donas y bollería con la mejor experiencia creando espacios únicos en cada una de sus locales.</p>
                  <p>Nuestra marca, Recién Horneado, busca transmitir exactamente su significado: muy temprano por la mañana preparamos productos de materia prima importada son horneados diariamente, para ofrecer la mas alta calidad y el mejor sabor en cada pieza de pan. Porque eso es lo que se merecen nuestros clientes, para ellos solo lo mejor.</p>
                  <p>Este sueño ya es una realidad en tres países de Latinoamérica: fundadores orgullosamente Salvadoreños con las primeras dos tiendas,  luego expandiendo a Guatemala y recientemente en Mexico con su primera tienda en ese país. Gracias por ser parte de este sueño ¡Vamos por más!
                </p>
                <p className="font-steinfeld">
                  La vida hay que saber hornearla ¡Y disfrutarla!
                </p>
              </div>
              <img className="hide-on-desktop" src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fimages%2FIMG_0407%201.png?alt=media&token=90ca82ff-128a-4f76-b311-f3e1302cbd45'} alt="Our story"/>
              {/* <Image
                className="hide-on-desktop"
                publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669914244/Recien%20Horneado%20Bakery/our_story_t781ow.jpg"
              />  */}
            </Col>
          </Row>
        </Container>
      </section>
      <section id="products-section" className="pt-5">
        <Container>
          <Row>
            <Col>
              <h3>productos</h3>
              <p>
                Panadería • Bollería dulce y salada • Donas • Bebidas calientes y frías • Desayunos
              </p>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode
                className="hide-on-mobile"
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 2,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F1.png?alt=media&token=b942846f-793e-4e44-84a4-402156b12ffe'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F10.png?alt=media&token=dd412dbc-3fab-40d5-8d67-b0997c38a3a5'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F11.png?alt=media&token=a557685f-82eb-427c-9e87-e1b5d014503e'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F2.png?alt=media&token=67c88b7f-fb16-48b4-ad04-d3a79d9354d6'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F3.png?alt=media&token=d68877ec-49c6-444c-b17a-2b725c836147'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F4.png?alt=media&token=d04a8eb4-7c1b-4d3b-96cf-4aebeafa1220'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F5.png?alt=media&token=83c5ead7-1db1-4766-bdd0-bc0fa56bf4f1'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F6.png?alt=media&token=8d374bac-610a-4c6a-bc03-2050e6b23253'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F7.png?alt=media&token=81327ecd-6f02-42ce-83fb-c70cf393da06'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F9.png?alt=media&token=bde8883d-a846-47a0-b18c-a6430dc35672'} alt="Product"/>
              </Carousel>
              <Row className="hide-on-desktop">
                <Col className="pr-0">
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 25
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F1.png?alt=media&token=b942846f-793e-4e44-84a4-402156b12ffe'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F10.png?alt=media&token=dd412dbc-3fab-40d5-8d67-b0997c38a3a5'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F11.png?alt=media&token=a557685f-82eb-427c-9e87-e1b5d014503e'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F2.png?alt=media&token=67c88b7f-fb16-48b4-ad04-d3a79d9354d6'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F3.png?alt=media&token=d68877ec-49c6-444c-b17a-2b725c836147'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F4.png?alt=media&token=d04a8eb4-7c1b-4d3b-96cf-4aebeafa1220'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F5.png?alt=media&token=83c5ead7-1db1-4766-bdd0-bc0fa56bf4f1'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F6.png?alt=media&token=8d374bac-610a-4c6a-bc03-2050e6b23253'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F7.png?alt=media&token=81327ecd-6f02-42ce-83fb-c70cf393da06'} alt="Product"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fproducts%2F9.png?alt=media&token=bde8883d-a846-47a0-b18c-a6430dc35672'} alt="Product"/>
              </Carousel>
              </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="locations-section" className="pt-5">
        <Container>
          <Row>
            <Col>
              <h3>ubicaciones</h3>
              <Row  className="hide-on-mobile" id="locations-row">
                <Col>
                  <Card onClick={toggleSv}>
                    <CardImg
                      alt="Location"
                      src={locationSv1}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <h3>EL SALVADOR</h3>  
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card onClick={toggleGuat}>
                    <CardImg
                      alt="Location"
                      src={locationGt1}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <h3>GUATEMALA</h3>  
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card onClick={toggleMex}>
                    <CardImg
                      alt="Location"
                      src={locationMx1}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <h3>MÉXICO</h3>  
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="hide-on-desktop">
                <Col className="pr-0">
                <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 25
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <Card onClick={toggleSv}>
                    <CardImg
                      alt="Location"
                      src={locationSv1}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <h3>EL SALVADOR</h3>  
                    </CardBody>
                  </Card>
                  <Card onClick={toggleGuat}>
                    <CardImg
                      alt="Location"
                      src={locationGt1}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <h3>GUATEMALA</h3>  
                    </CardBody>
                  </Card>
                  <Card onClick={toggleMex}>
                    <CardImg
                      alt="Location"
                      src={locationMx1}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <h3>MÉXICO</h3>  
                    </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Modal id="menus-modal" size="xl" isOpen={menusModal} toggle={toggleMenus}>
                <ModalHeader toggle={toggleMenus}>MENÚS</ModalHeader>
                <ModalBody>
                  <Row className="mb-4">
                    <Col className="full-width-col-on-mobile">
                      <img className="mb-2" alt="Location" src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcountries%2Felsalvador.png?alt=media&token=67635185-a395-4285-a801-8390ab085607'}/>
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065798/Recien%20Horneado%20Bakery/elsalvador_utszqo.png"
                      />  */}
                      <p className="mb-1 bold">El Salvador</p>
                      <form action="https://drive.google.com/file/d/13sYQM2JKK1FbZnzzuhX4crp0HjS8kWRl/view" method="get" target="_blank">
                        <Button>VER MENÚ</Button>
                      </form>
                    </Col>
                    <Col className="full-width-col-on-mobile">
                      <img className="mb-2" id="guatemala" alt="Location" src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcountries%2Fguatemala.png?alt=media&token=6c81e1a9-7492-4d50-ac07-3759169e7fbd'}/>
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065798/Recien%20Horneado%20Bakery/guatemala_ycsvxw.png"
                      />  */}
                      <p id="guatemala-outline-label" className="mb-1 bold">Guatemala</p>
                      <form action="https://drive.google.com/file/d/1rRdCt2A7ek0EFii47GaEMRK1SgZ_d6d0/view" method="get" target="_blank">
                        <Button>VER MENÚ</Button>
                      </form>
                    </Col>
                    <Col className="full-width-col-on-mobile">
                      <img className="mb-2" alt="Location" src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcountries%2Fmexico.png?alt=media&token=84d4ce4f-a52b-4638-a666-6ffdc995441a'}/>
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065798/Recien%20Horneado%20Bakery/mexico_si1lbk.png"
                      />  */}
                      <p className="mb-1 bold">México</p>
                      <form action="https://drive.google.com/file/d/1tXRkqbYyUYSG66kN0YhSdVyj3rLvIM1v/view" method="get" target="_blank">
                        <Button>VER MENÚ</Button>
                      </form>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal className="locations-modal" size="xl" isOpen={svModal} toggle={toggleSv}>
                <ModalHeader toggle={toggleSv}>EL SALVADOR</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      {/* <img className="mb-2"  alt="Location" src={locationSv1}/> */}
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065974/Recien%20Horneado%20Bakery/sv1_oxywnp.png"
                      />  */}
                      <p className="rh-pink bold">San Benito, San Salvador</p>
                      <p className="rh-grey mb-2">Blvd Hipódromo</p>
                      <Row className="location-row">
                        <Col className="location-opening-times-col">
                          <p className="rh-grey opening-times">Lunes a Sabado: 10AM - 7PM<br/>Domingo: 11AM - 7PM</p>
                          <p className="rh-grey opening-times"><FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} />+503 7097 9624</p>
                        </Col>
                        <Col className="location-button-col">
                          <Button onClick={() => showInMapClicked("sv1")}>
                            <FontAwesomeIcon className="location-icon" icon={faLocationArrow} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      {/* <img className="mb-2"  alt="Location" src={locationSv2}/> */}
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065974/Recien%20Horneado%20Bakery/sv2_m7cfx6.png"
                      />  */}
                      <p className="rh-pink bold">Santa Tecla, La Libertad</p>
                      <p className="rh-grey mb-2">CC La Skina</p>
                      <Row className="location-row">
                        <Col className="location-opening-times-col">
                          <p className="rh-grey opening-times">Lunes a Jueves: 11AM - 7PM<br/>Viernes: 11AM - 8PM<br/>Sabado a Domingo: 8AM - 8PM</p>
                          <p className="rh-grey opening-times"><FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} />+503 7097 9624</p>
                        </Col>
                        <Col className="location-button-col pt-2">
                          <Button onClick={() => showInMapClicked("sv2")}>
                            <FontAwesomeIcon className="location-icon" icon={faLocationArrow} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal className="locations-modal" size="md" isOpen={guatModal} toggle={toggleGuat}>
                <ModalHeader toggle={toggleGuat}>GUATEMALA</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      {/* <img className="mb-2" alt="Location" src={locationGt1}/> */}
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065975/Recien%20Horneado%20Bakery/gt1_o5ywex.png"
                      />  */}
                      <p className="rh-pink bold">Ciudad de Guatemala</p>
                      <p className="rh-grey">Ciudad Cayalá</p>
                      <Row className="location-row">
                        <Col className="location-opening-times-col">
                          <p className="rh-grey opening-times">Lunes a Jueves: 9AM - 8PM<br/>
                            Viernes a Sabado: 9AM - 9PM<br/>
                            Domingo: 9AM - 8:00PM</p>
                          <p className="rh-grey opening-times"><FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} />+502 3572 2422</p>
                        </Col>
                        <Col className="location-button-col pt-2">
                          <Button onClick={() => showInMapClicked("gt1")}>
                            <FontAwesomeIcon className="location-icon" icon={faLocationArrow} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal className="locations-modal" size="md" isOpen={mexModal} toggle={toggleMex}>
                <ModalHeader toggle={toggleMex}>MÉXICO</ModalHeader>
                <ModalBody>
                  <Row>
                    <Col>
                      {/* <img className="mb-2" alt="Location" src={locationMx1}/> */}
                      {/* <Image
                        className="mb-2"
                        publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669065973/Recien%20Horneado%20Bakery/mx1_ct0jyd.png"
                      />  */}
                      <p className="rh-pink bold">Ciudad de México</p>
                      <p className="rh-grey">Calle Rio Lerma 45</p>
                      <Row className="location-row">
                        <Col className="location-opening-times-col">
                          <p className="rh-grey opening-times">Lunes a Domingo: 8AM - 8PM</p>
                          <p className="rh-grey opening-times"><FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} />+52 55 8499 3470</p>
                        </Col>
                        <Col className="location-button-col pt-2">
                          <Button onClick={() => showInMapClicked("mx1")}>
                            <FontAwesomeIcon className="location-icon" icon={faLocationArrow} />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="catering-section" className="pt-5">
        <Container>
          <Row>
            <Col>
              <h3>catering</h3>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode
                className="hide-on-mobile"
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 2,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC03917.png?alt=media&token=0e0be41d-63bf-433d-a19d-cae44e2a6f8d'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC03918.png?alt=media&token=55a3710b-3cef-46ce-a30c-990b11c05cda'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC03993.png?alt=media&token=995f7867-4711-4c80-8a29-00366be34fdd'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC04065.png?alt=media&token=316ea83c-5b66-400b-82bc-5d4d889cb9c0'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC04089.png?alt=media&token=4cbb81d0-9a64-4532-8f66-943568da90db'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC04105.png?alt=media&token=2636f532-10cd-4049-a90b-0891af3f237a'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FIMG_5407.png?alt=media&token=40fc668c-9d05-455c-9ade-65b24c168ba1'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FIMG_5444.png?alt=media&token=eb37435d-84b5-49f0-ac92-04e161113dd2'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FIMG_5451.png?alt=media&token=f9b0b40b-3d47-4e5f-93ae-b068fd25234d'} alt="Catering"/>
              </Carousel>
              <Row className="hide-on-desktop">
                <Col className="pr-0">
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 25
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC03917.png?alt=media&token=0e0be41d-63bf-433d-a19d-cae44e2a6f8d'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC03918.png?alt=media&token=55a3710b-3cef-46ce-a30c-990b11c05cda'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC03993.png?alt=media&token=995f7867-4711-4c80-8a29-00366be34fdd'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC04065.png?alt=media&token=316ea83c-5b66-400b-82bc-5d4d889cb9c0'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC04089.png?alt=media&token=4cbb81d0-9a64-4532-8f66-943568da90db'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FDSC04105.png?alt=media&token=2636f532-10cd-4049-a90b-0891af3f237a'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FIMG_5407.png?alt=media&token=40fc668c-9d05-455c-9ade-65b24c168ba1'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FIMG_5444.png?alt=media&token=eb37435d-84b5-49f0-ac92-04e161113dd2'} alt="Catering"/>
                <img src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Fcatering%2FIMG_5451.png?alt=media&token=f9b0b40b-3d47-4e5f-93ae-b068fd25234d'} alt="Catering"/>
              </Carousel>
              </Col>
              </Row>
              <form action="https://wa.me/50377446621" method="get" target="_blank">
                <Button className="mt-5">COTIZA AQUÍ</Button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
      <footer className="dark-grey">
        <Row>
          <Col>
            <Row>
              <Col className="hide-on-mobile">
              </Col>
              <Col className="align-center">
                <img id="footer-logo" className="ml-3 mb-2" src={'https://firebasestorage.googleapis.com/v0/b/recien-horneado-bakery.appspot.com/o/assets%2Flogos%2FRH%20LOGO%20BLANCO.png?alt=media&token=a76df2d4-144b-489f-aa85-1f3d63e815a8'} alt="Recien Horneado" />
                {/* <Image
                  id="footer-logo"
                  className="ml-3 mb-2"
                  publicId="https://res.cloudinary.com/da3qtg7t5/image/upload/q_auto,f_auto,fl_lossy/v1669066363/Recien%20Horneado%20Bakery/rh_logo_blanco_a3vnlh.png"
                /> */}
                <div className="footer-socials">
                <a href="https://www.facebook.com/recienhorneadosv">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/recienhorneadosv/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://www.tiktok.com/@recienhorneadobakery">
                  <TiktokIcon id="tiktok-icon" />
                </a>
                </div>
              </Col>
              <Col className="hide-on-mobile">
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p id="copyright">© 2022 Recién Horneado, all rights reserved</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
      <a
        href="https://wa.me/50378548791"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="whatsapp-icon" icon={faWhatsapp} />
      </a>
    </div>
    </CloudinaryContext>
  );
}

export default App;
